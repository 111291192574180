<template>
    <div class="main-pdding">
        <div class="main-box">
            <div class="main-between">
                <div class="title">
                    <label>产品分类</label>
                </div>
                <div class="new-product">
                    <el-button type="primary" icon="el-icon-plus" @click="addproduct">添加分类</el-button>
                </div>
            </div>

            <div class="info-table search">
                <infoTable :selection="false" :tableLoading="tableLoading" :tableHeadConfig="tableHead" :tableLoadData="tableData">
                    <template v-slot:img="slotData">
                        <img style="width: 70px; height: 70px" :src="slotData.data.row.pic" />
                    </template>
                    <template v-slot:state="slotData">
                        <el-switch
                            v-model="slotData.data.row.is_status"
                            :active-value="1"
                            :inactive-value="0"
                            @change="stateChange('is_status', slotData.data.row)"
                        >
                        </el-switch>
                    </template>
                    <template v-slot:show="slotData">
                        <el-switch
                            v-model="slotData.data.row.is_display"
                            :active-value="1"
                            :inactive-value="0"
                            @change="stateChange('is_display', slotData.data.row)"
                        >
                        </el-switch>
                    </template>
                    <template v-slot:operation="slotData">
                        <div class="operation-sty">
                            <span @click="goEdit(slotData.data.row)"> 编辑 </span>
                            <span @click="delInfo(slotData.data.row.id)">删除</span>
                        </div>
                    </template>
                </infoTable>
            </div>
        </div>
        <deldialog :appId="classId" title="是否确定要删除该分类?" @dialogChange="delChange" :dialogVal="delDialog"></deldialog>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
export default {
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        this.getClass();
    },
    data() {
        return {
            classId: "",
            delDialog: false,
            platform_id: "",
            tableLoading: false,
            tableHead: [
                {
                    label: "ID",
                    field: "id",
                    sort: true,
                },
                // {
                //     label: "标题",
                //     field: "title",
                // },
                {
                    label: "产品分类",
                    field: "name",
                },
                {
                    label: "分类图片",
                    columnType: "slot",
                    slotName: "img",
                },
                {
                    label: "是否启用",
                    columnType: "slot",
                    slotName: "state",
                },
                {
                    label: "是否显示",
                    columnType: "slot",
                    slotName: "show",
                },
                {
                    label: "操作",
                    columnType: "slot",
                    slotName: "operation",
                },
            ],
            tableData: [],
        };
    },
    methods: {
        getClass() {
            const that = this;
            that.tableLoading = true;
            that.$request({
                url: that.$api.goodClass.getClass,
                method: "post",
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
               this.tableData = res.data;
                console.log()
                if(Array.isArray(res.data)){
                     this.tableData = res.data;
                }else{
                      this.tableData=Object.values(res.data)
                }
                
                
               
                that.tableLoading = false;
            });
        },
        // 是否上下架
        stateChange(name, row) {
            const that = this
            let data = {};
            if (name == "is_status") {
                data = {
                    id: row.id,
                    is_status: row.is_status,
                };
            } else if (name == "is_display") {
                data = {
                    id: row.id,
                    is_display: row.is_display,
                };
            }
            that.$request({
                url: that.$api.goodClass.editClass,
                method: "post",
                data:data,
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
                that.getClass()
            });
        },
        addproduct() {
            this.$router.push({
                path: "class/addclass",
                query: { type: "1" },
            });
        },
        goEdit(row) {
            this.$router.push({
                path: "class/addclass",
                query: {
                    data: JSON.stringify(row),
                    type: "2",
                },
            });
        },
        delInfo(id) {
            this.classId = id;
            this.delDialog = true;
        },
        delChange(type, id) {
            const that = this;
            that.delDialog = false;
            if (type == 1) {
                that.$request({
                    url: that.$api.goodClass.delClass,
                    method: "post",
                    data: {
                        id,
                    },
                    params: {
                        platform_id: that.platform_id,
                    },
                }).then((res) => {
                    that.getClass();
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    label {
        display: inline-block;
        height: 30px;
        color: #4458fe;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 2px solid #4458fe;
    }
}

.search {
    margin-top: 15px;
}

.info-table {
    min-height: 400px;

    .sort-sty {
        span {
            font-size: 16px;
        }

        img {
            margin-left: 10px;
            width: 22px;
            height: 22px;
        }
    }

    .state-young {
        width: 80px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #01d0b5;
        background-color: #e5fffc;
        border: 1px solid #01d0b5;
        border-radius: 6px;
    }

    .state-red {
        width: 80px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #fe725c;
        background-color: #ffe6e2;
        border: 1px solid #fe725c;
        border-radius: 6px;
    }

    .operation-sty {
        span {
            color: #4458fe;
            cursor: pointer;
        }

        span:before {
            content: "|";
            margin: 0 5px;
            color: #4458fe;
        }

        span:first-child:before {
            display: none;
        }
    }
}
</style>
